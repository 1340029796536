import { Button, Loading, Modal, ModalProps, SeparatorLine } from '@/components';
import { RadioGroup } from '@headlessui/react';
import { Fragment, useState } from 'react';

type MoreAnchorTextActions = {
  createNew: () => void | Promise<void>;
  startOver: () => void | Promise<void>;
  duplicateCurrentBatch: () => void | Promise<void>;
};

interface MoreAnchorTextModalProps extends ModalProps {
  actions: MoreAnchorTextActions;
}

const DISABLED_OPTIONS = ['duplicate_current_batch', 'create_new'];

type AnchorTextValue = 'create_new' | 'start_over' | 'duplicate_current_batch';
type AnchorTextOption = {
  label: string;
  text: string;
  value: AnchorTextValue;
};

const ANCHOR_TEXT_OPTIONS: Array<AnchorTextOption> = [
  {
    label: 'Create New',
    text: 'Create new anchor text batch starting from scratch without replacing the current batch configurations.',
    value: 'create_new',
  },
  {
    label: 'Start Over',
    text: 'Create anchor text batch starting from scratch with replacing the current batch configurations.',
    value: 'start_over',
  },
  {
    label: 'Duplicate Current Batch',
    text: 'Duplicate the current anchor text batch with the same keyword allocation configurations.',
    value: 'duplicate_current_batch',
  },
];

const DEFAULT_OPTION = 'start_over';

const MoreAnchorTextModal = ({ isOpen, onClose, actions, isLoading }: MoreAnchorTextModalProps) => {
  const [selectedOption, setSelectedOption] = useState<AnchorTextValue>(DEFAULT_OPTION);

  const handleNewBatchOnClick = async () => {
    const { createNew, duplicateCurrentBatch, startOver } = actions;
    if (selectedOption === 'create_new') {
      await createNew();
    }
    if (selectedOption === 'start_over') {
      await startOver();
    }
    if (selectedOption === 'duplicate_current_batch') {
      await duplicateCurrentBatch();
    }
    onClose && onClose();
  };

  const renderOptions = (option: AnchorTextOption, index: number) => {
    const isDisabled = DISABLED_OPTIONS.includes(option.value);
    return (
      <Fragment key={option.value}>
        <div className='flex space-y-1 p-4'>
          <RadioGroup.Option className='p-1' value={option.value} disabled={isDisabled}>
            {() => (
              <input
                type='radio'
                disabled={isDisabled}
                checked={option.value === selectedOption}
                id={option.value}
                className={
                  'checked:bg-sky-600 checked:hover:bg-sky-600 focus:bg-sky-600 focus:outline-none focus:ring-1 focus:ring-sky-600 checked:focus:bg-sky-600 checked:active:bg-sky-600 disabled:bg-slate-400'
                }
              />
            )}
          </RadioGroup.Option>
          <div>
            <h3 className='text-base'>{option.label}</h3>
            <p className='text-slate-500'>{option.text}</p>
          </div>
        </div>
        {index !== ANCHOR_TEXT_OPTIONS.length - 1 && <SeparatorLine className='px-8' />}
      </Fragment>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      className='w-[28rem]'
      onClose={onClose}
      title={'More Anchor Text Batches'}
      footer={<MoreAnchorTextModalFooter onClick={handleNewBatchOnClick} isSubmitting={isLoading} />}
    >
      <RadioGroup value={selectedOption} onChange={setSelectedOption}>
        {ANCHOR_TEXT_OPTIONS.map(renderOptions)}
      </RadioGroup>
    </Modal>
  );
};

export default MoreAnchorTextModal;

interface MoreAnchorTextModalFooterProps {
  onClick: () => void;
  isSubmitting?: boolean;
}

const MoreAnchorTextModalFooter = ({ onClick, isSubmitting }: MoreAnchorTextModalFooterProps) => {
  return (
    <div className='flex justify-center'>
      <Button className='w-full' disabled={isSubmitting} onClick={onClick}>
        {isSubmitting ? <Loading className=' mx-auto size-5' /> : 'Start New Batch'}
      </Button>
    </div>
  );
};
