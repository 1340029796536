import { AnchorIcon, Button, ErrorMessage } from '@/components';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import GeneratingAnchorTextsModal from './GeneratingAnchorTextsModal';
import { useToggle } from '@/hooks/toggle';
import { useLazyGetCampaignQuery, useRegenerateUrlAnchorTextsMutation, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCampaign, selectScenarios } from '@/store/campaign/campaign.selector';
import { AppDispatch } from '@/store/store';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { updateCampaignScenarioMutation } from '@/store/reducers';

type GenerateAnchorTextButtonProps = {
  selectedTerm: number;
  selectedMehthodUrls: UrlAnchorTextTableColumns[];
  distributions: Map<number, AnchorTextKeywordState[]>;
};

const GenerateAnchorTextButton: FC<GenerateAnchorTextButtonProps> = ({ selectedTerm, selectedMehthodUrls, distributions }) => {
  const { campaignId, batchId } = useParams() as { campaignId: string; batchId: string };
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const campaign = useSelector(selectCampaign);
  const scenarios = useSelector(selectScenarios);

  const [saveCampaignConfig] = useSaveCampaignConfigMutation();

  const [regenerateUrlAnchorTexts, { isLoading: isGeneratingAnchors }] = useRegenerateUrlAnchorTextsMutation();

  const [fetchCampaign] = useLazyGetCampaignQuery();

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [anchorResponseError, setAnchorResponseError] = useState<string>('');
  const approvedScenario = useMemo(() => scenarios.find((s) => s.is_approved), [scenarios]);
  const { value: isLoadingModalOpen, toggle: toggleLoadingModal } = useToggle({
    initialValue: Boolean(campaign && campaign.anchors_generation_status === 'in_progress'),
  });

  const updateScenario = useCallback(async () => {
    if (!approvedScenario) {
      return;
    }

    const urls = selectedMehthodUrls.map((url) => ({
      id: url.url_id,
      counts: {
        generic: url.generic_match,
        partial: url.partial_match,
        exact: url.exact_match,
      },
      is_allocated: url.is_allocated,
    }));

    const newURLAnchorCounts = [...approvedScenario.urls].map((url) => {
      const newURL = urls.find((u) => u.id === url.url_id);
      if (newURL) {
        return {
          ...url,
          is_allocated: newURL.is_allocated,
          anchor_text: {
            exact: newURL.counts.exact,
            partial: newURL.counts.partial,
            generic: newURL.counts.generic,
          },
          anchors_per_keyword_distribution: distributions.get(url.url_id) || [],
        };
      }
      return url;
    });

    await dispatch(updateCampaignScenarioMutation({ campaignId, scenario: { ...approvedScenario, urls: newURLAnchorCounts, selected_term_for_anchors: selectedTerm } }));
    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: false }));
  }, [campaignId, approvedScenario, selectedMehthodUrls, dispatch, selectedTerm, distributions]);

  const generateAnchorTexts = useCallback(async () => {
    const urls = selectedMehthodUrls.map((url) => {
      const keywordDistribution = distributions.get(url.url_id) || [];
      const isKeywordDistributed = keywordDistribution.some(({ exact_match, partial_match, generic_match }) => exact_match !== 0 || partial_match !== 0 || generic_match !== 0);

      return {
        id: url.url_id,
        counts: {
          generic: url.generic_match,
          partial: url.partial_match,
          exact: url.exact_match,
          anchors_per_keyword_distribution: isKeywordDistributed ? keywordDistribution : [],
        },
      };
    });

    try {
      await regenerateUrlAnchorTexts({ campaign_id: campaignId, urls }).unwrap();
    } catch (error) {
      const APIError = error as APIError;
      setAnchorResponseError(APIError.data.message);
      return;
    }

    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: true }));
  }, [campaignId, selectedMehthodUrls, regenerateUrlAnchorTexts, dispatch, distributions]);

  const updateCampaignConfig = useCallback(async () => {
    await saveCampaignConfig({ campaignId, config: { user_progress: { last_step: 'summary' } } }).unwrap();
  }, [campaignId, saveCampaignConfig]);

  const handleGeneratingAnchorTextsModalClose = useCallback(() => {
    toggleLoadingModal(false);
    setAnchorResponseError('');
  }, [toggleLoadingModal]);

  const handleGenerate = useCallback(async () => {
    const isError = selectedMehthodUrls.some((url) => url.generic_match + url.partial_match + url.exact_match !== url.links_per_month);

    if (isError) {
      setErrorMsg('Anchor text distribution must be equal to the number of links per month');
      return;
    }

    setErrorMsg('');
    toggleLoadingModal(true);

    await updateScenario();
    await updateCampaignConfig();
    await generateAnchorTexts();

    await fetchCampaign({ campaignId });

    navigate('../anchor-text-results' + (batchId ? `/${batchId}` : '/default'));
    toggleLoadingModal(false);
  }, [generateAnchorTexts, selectedMehthodUrls, toggleLoadingModal, updateCampaignConfig, updateScenario, navigate, fetchCampaign, campaignId, batchId]);

  return (
    <Fragment>
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      <Button onClick={handleGenerate} className='flex items-center gap-2' isLoading={isGeneratingAnchors}>
        <AnchorIcon className='w-6 text-white' />
        Generate
      </Button>
      {isLoadingModalOpen && <GeneratingAnchorTextsModal isOpen onClose={handleGeneratingAnchorTextsModalClose} onRegenerate={handleGenerate} errorMessage={anchorResponseError} />}
    </Fragment>
  );
};

export default GenerateAnchorTextButton;
