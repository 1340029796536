import { Button } from '@/components';
import { useSteps } from '@/hooks/steps';
import { useToggle } from '@/hooks/toggle';
import { Page } from '@/layouts';
import { useDeleteCampaignAnchorTextsMutation, useGetCampaignQuery, useSaveCampaignConfigMutation } from '@/store/campaign/campaign.api';
import { selectScenarios, selectSteps } from '@/store/campaign/campaign.selector';
import { setIsDirty } from '@/store/campaign/campaign.slice';
import { AppDispatch } from '@/store/store';
import cn from '@/utils/style';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import { AnalysisFooter, AnalysisTopbar } from '../../components';
import FinalizeSectionPageSkeleton from '../FinalizeSectionPage/FinalizeSectionPageSkeleton';
import { AnchorTextResultsTabs, ReadyToReviewModal, StartOverModal } from './components';
import AnchorTextGeneratorPageSkeleton from '../AnchorTextGeneratorPage/AnchorTextGeneratorPageSkeleton';
import { updateCampaignScenarioMutation } from '@/store/reducers';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { MoreAnchorTextModal } from './components/MoreAnchorTextModal';

const AnchorTextResultsPage = () => {
  const { campaignId, batchId } = useParams() as { campaignId: string; batchId: string };
  const navigate = useNavigate();
  const navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const steps = useSelector(selectSteps);
  const scenarios = useSelector(selectScenarios);
  const { previousStep, nextStep } = useSteps();

  const [isScenarioUpdating, setIsScenarioUpdating] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(0);

  const { data: campaign, isLoading: loadingCampaign } = useGetCampaignQuery({ campaignId }, { pollingInterval });
  const [deleteAnchors, { isLoading: deletingAnchors }] = useDeleteCampaignAnchorTextsMutation();
  const [updateConfig, { isLoading: isUpdatingConfig }] = useSaveCampaignConfigMutation();

  const { value: isMoreAnchorTextModalOpen, toggle: toggleMoreAnchorTextModal } = useToggle();
  const { value: isRegenerateModalOpen, toggle: toggleRegenerateModal } = useToggle();
  const { value: isReadyToReviewModalOpen, toggle: toggleReadyToReviewModal } = useToggle();
  const approvedScenario = useMemo(() => scenarios.find(({ is_approved }) => is_approved), [scenarios]);
  const inProgress = useMemo(() => ['in_progress', 'queued'].includes(campaign?.anchors_generation_status || ''), [campaign]);

  const handleSave = useCallback(() => {
    dispatch(setIsDirty({ step: 'anchor-text-generator', isDirty: false }));
  }, [dispatch]);

  useEffect(() => {
    if (inProgress) {
      return setPollingInterval(3000);
    }

    setPollingInterval(0);
  }, [inProgress]);

  if (!approvedScenario) {
    return null;
  }

  const deleteAllAnchors = async () => {
    await deleteAnchors({ campaignId });
    setIsScenarioUpdating(true);
    const urls = approvedScenario.urls.map((url) => ({ ...url, is_allocated: false }));
    await dispatch(
      updateCampaignScenarioMutation({
        campaignId: campaignId.toString(),
        scenario: {
          ...approvedScenario,
          urls: urls,
        },
      }),
    );
    setIsScenarioUpdating(false);

    await updateConfig({
      campaignId: campaignId.toString(),
      config: {
        ...campaign?.config,
        keywords: [],
        has_anchor_texts: false,
      },
    });
    navigate('../anchor-text-generator' + (batchId ? `/${batchId}` : '/default'));
  };

  const handlePrevious = () => navigate(`../${previousStep}`);
  const handleNext = async () => {
    if (!isReadyToReviewModalOpen) {
      return toggleReadyToReviewModal(true);
    }

    await updateConfig({ campaignId, config: { user_progress: { last_step: 'summary' } } });
    navigate(`../${nextStep}`);
  };

  if (loadingCampaign) {
    return (
      <div className='absolute inset-0 z-10 bg-slate-50'>
        <FinalizeSectionPageSkeleton />;
      </div>
    );
  }

  if (navigation.state === 'loading') {
    return <AnchorTextGeneratorPageSkeleton />;
  }

  if (!campaign || !approvedScenario) {
    return null;
  }

  return (
    <Fragment>
      <Page>
        <AnalysisTopbar onSave={handleSave} isDirty={steps['anchor-text-generator'].isDirty} />
        <Page.Body>
          <div className='flex justify-between'>
            <h2 className='text-left font-semibold'>Anchor Text Generator</h2>
            <div className='flex gap-2'>
              <Button disabled={inProgress} className='flex items-center gap-2 text-sm text-slate-800' variant='outline-light' onClick={() => toggleRegenerateModal(true)}>
                <TrashIcon className={cn('size-5 text-slate-400')} />
                Delete Batch
              </Button>
              <Button disabled={inProgress} className='flex items-center gap-2 text-sm' onClick={() => toggleMoreAnchorTextModal(true)}>
                <PlusIcon className={cn('size-5')} />
                More Anchor Text Batches
              </Button>
            </div>
          </div>
          <AnchorTextResultsTabs isLoading={inProgress} />
        </Page.Body>
        <Page.Footer>
          <AnalysisFooter nextDisabled={inProgress} onPrevious={handlePrevious} onSave={handleSave} onNext={handleNext} />
        </Page.Footer>
      </Page>
      {isMoreAnchorTextModalOpen && (
        <MoreAnchorTextModal
          actions={{
            createNew: () => {},
            duplicateCurrentBatch: () => {},
            startOver: deleteAllAnchors,
          }}
          isOpen={isMoreAnchorTextModalOpen}
          onClose={() => toggleMoreAnchorTextModal(false)}
          isLoading={deletingAnchors || isScenarioUpdating || isUpdatingConfig}
        />
      )}
      {isRegenerateModalOpen && (
        <StartOverModal
          isOpen={isRegenerateModalOpen}
          onClose={() => toggleRegenerateModal(false)}
          confirmLoading={deletingAnchors || isScenarioUpdating || isUpdatingConfig}
          onStartOver={deleteAllAnchors}
        />
      )}
      {isReadyToReviewModalOpen && <ReadyToReviewModal isOpen={true} onClose={() => toggleReadyToReviewModal(false)} onReview={handleNext} />}
    </Fragment>
  );
};

export default AnchorTextResultsPage;
