import { SearchBarDebounce, Table } from '@/components';
import { useTable, useTableHandlersWithFilters } from '@/hooks/table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import useURLsTableColumns from './URLsColumns';
import { useFilterContext } from '@/hooks/filters';
import { useGetURLListQuery } from '@/store/poc/poc.api';
import URLPageSkeleton from '../../URLPageSkeleton';
import { useSearchParams } from 'react-router-dom';

const PAGE_SIZE = 20;

const AVAILABLE_STATUS_TO_POLLING = ['in_progress', 'created', 'queued'];

const URLsTable: FC = () => {
  const [pollingInterval, setPollingInterval] = useState(0);
  const { filters, changePage } = useFilterContext();
  const [searchParam, setSearchParam] = useSearchParams();
  const { handlePaginationChange, pagination } = useTableHandlersWithFilters(filters);
  const { data, isLoading, isFetching } = useGetURLListQuery({ page: filters.page || 1, search: searchParam.get('search') || '' }, { pollingInterval });
  const pageCount = useMemo(() => Math.ceil((data?.count || 0) / PAGE_SIZE), [data?.count]);

  const inProgress = useMemo(() => data?.results.some((url) => AVAILABLE_STATUS_TO_POLLING.includes(url.status)), [data]);

  useEffect(() => {
    if (inProgress) {
      return setPollingInterval(3000);
    }

    setPollingInterval(0);
  }, [inProgress]);

  const handleSearch = useCallback(
    (value: string) => {
      if (value === searchParam.get('search')) return;

      changePage(1, PAGE_SIZE);
      setSearchParam((prevState) => {
        prevState.set('search', value);
        if (prevState.get('page')) {
          prevState.set('page', '1');
        }
        return prevState;
      });
    },
    [searchParam, setSearchParam, changePage],
  );

  const columns = useURLsTableColumns();

  const [table] = useTable({
    data: data?.results || [],
    columns,
    tableCustomOptions: ['allowSort'],
    tableOptions: {
      onPaginationChange: handlePaginationChange,
      state: {
        pagination: {
          ...pagination,
          pageSize: PAGE_SIZE,
        },
      },
      manualPagination: true,
      pageCount,
    },
  });

  if (isLoading) {
    return <URLPageSkeleton />;
  }

  return (
    <div className='rounded-leap bg-white p-4'>
      <SearchBarDebounce
        delay={750}
        onChange={handleSearch}
        inputClassName='w-96 h-10'
        className='mb-2 w-full'
        isFetching={isFetching && !inProgress}
        initialValue={searchParam.get('search') || ''}
      />
      <Table
        table={table}
        name='pocUrls'
        totalRows={data?.count || 0}
        containerClass='max-h-[70vh] overflow-y-auto [&>div]:sticky [&>div]:bottom-0'
        tableClass='shadow-none'
        onPageChange={changePage}
        loadingText='Loading URLs...'
        isLoading={isLoading || (isFetching && !inProgress)}
      />
    </div>
  );
};

export default URLsTable;
